* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  
}

:root {

    /* colors */
    --color-primary-100: #b1ddf6;
    --color-primary-200: #789FB5;
    --color-primary-300: #577688;
    --color-primary-400: #3F5665;
    --color-primary-500: #2B3A42;
    --color-primary-600: #222E34;
    --color-primary-700: #1a2328;
    --color-primary-800: #11171A;
    --color-primary-900: #080B0D;

    --color-secondary-100: #E66E68;
    --color-secondary-200: #E35952;
    --color-secondary-300: #DF443d;
    --color-secondary-400: #FA372F;
    --color-secondary-500: #DC3028;
    --color-secondary-600: #C62B24;
    --color-secondary-800: #B02620;
    --color-secondary-900: #9A211C;
  
    /* grey */
    --color-grey-50: #f8fafc;
    --color-grey-100: #f5f5f5;
    --color-grey-200: #dcdcdc;
    --color-grey-300: #c4c4c4;
    --color-grey-400: #939393;
    --color-grey-500: #7a7a7a;
    --color-grey-600: #626262;
    --color-grey-700: #494949;
    --color-grey-800: #313131;
    --color-grey-900: #181818;

    /* rest of the colors */
    --color-black: #222;
    --color-white: #fff;
    --color-red-light: #ff939c;
    --color-red-dark: #842029;
    --color-green-light: #11ff94;
    --color-green-dark: #0d5835;

    --color-bg: var(--color-grey-100);

  /* widths  */

    --container-width-lg: 80%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    /* box shadow*/
    --shadow-1: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 0px 2px 0 rgba(0, 0, 0, .06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);

  /* fonts  */

    --small-text: 0.875rem;
    --extra-small-text: 0.7em;

  /* rest of vars  */
    --nav-height: 80px;
    --transition: all 400ms ease;

  }

html {
  scroll-behavior: smooth;
  /* scroll-padding-top: var(--nav-height); */
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  background: var(--color-primary-500);
  color: var(--color-white);

}

body {
  font-family: 'LotaGrotesqueAlt3-Regular', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-bg);
  color: var(--color-primary-700);
  line-height: 1.7;
}

/* General Styles */

.sr-only {
  clip: rect(1px, 1px, 1px,1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, 
h2, 
h3, 
h4, 
h5 {
  font-family: 'LotaGrotesqueAlt3-Bold', Arial, Helvetica, sans-serif;
  color: var(--color-primary-500);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

.subhead {
  font-family: 'LotaGrotesqueAlt3-Bold', Arial, Helvetica, sans-seif;
  font-size: 1.25rem;
  font-weight: 800;
  display: block;
  color: var(--color-primary-500);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

.section {
  margin-top: 8rem;
}

.section > h2,
.section > h5 {
  text-align: center;
}

.section > h2 {
  color: var(--color-primary-500);
  margin-bottom: 3rem;
}

a {
  color: var(--color-secondary-500);
  transition: var(--transition);
}

a:hover {
  color: var(--color-secondary-600);
}

a:active {
  color: var(--color-secondary-400)
}
/* 
a:visited {
  color: var(--color-secondary-800);
} */




 .primary-button {
  width: max-content;
  color: var(--color-primary-500);
  background-color: var(--color-bg);
  display: inline-block;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: var(--transition);
  font-weight: 700;
  border: 2px solid var(--color-secondary-500);
  box-shadow: inset 0 0 0 0 var(--color-secondary-500);
}



.primary-button:hover {
  box-shadow: inset 0 0 0 50px var(--color-secondary-500);
  color: var(--color-white);

}

button:hover {
  box-shadow: inset 0 0 0 50px var(--color-secondary-500);
  color: var(--color-white);

}

.primary-button:active {
  box-shadow: var(--color-secondary-400);
  color: var(--color-white); 
  background-color: var(--color-secondary-400);
}


.text-light {
  color: var( --color-primary-500);
  opacity: 60%;
}

.text-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}