#experience {
    padding: 3rem 0;
}

.e-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: var(--container-width-lg);

}


.e-title {
    line-height: 1.2;
}

.e-note {
    font-size: 1rem;
    width: 80%;
    margin: 1rem auto 2rem auto;
    line-height: 1.2;
    font-family: 'LotaGrotesqueAlt3-Bold', Arial, Helvetica, sans-seif;
    font-weight: 800;   
    text-align: center;
    display: block;
}

.e-container > div {
    background: var(--color-bg-variant);
    padding: 2.5rem 2.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.e-container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.e-container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
}

.e-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 4rem;
}

.e-details {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: left;    
}

.e-check {
    font-size: 1.5rem;
}


h4.e-skill {
    font-size: .875rem;
    line-height: 1.5rem;
}

.plus {
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 0;
    color: var(--color-secondary-500);
    font-weight: 900;
}


/* MEDIA QUERIES - MEDIUM DEVICES */

@media screen and (max-width: 1024px) {

    .e-container {
        grid-template-columns: 1fr;
    }

    .e-container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .e-content {
        padding: 1rem;
    }

}


/* MEDIA QUERIES - SMALL DEVICES */

@media screen and (max-width: 600px) {

    .e-container {
        gap: 1rem;
    }

    .e-container > div {
        width: 100%;
        padding: 2rem 1rem;
         border-color: var(--color-primary-variant);
    }
}


@media screen and (max-width: 400px) {


    .e-content {
        grid-template-columns: 1fr;
    }


}