.i-2-col {
    display: flex;
    height: 100vh;
    min-height: 700px;
    max-width: 80%;
    margin: auto;
}

.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.i-right {
    flex: 1;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.i-left-wrapper {
    padding: 50px;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.i-intro {
    font-size: 30px;
    text-align: center;
}

.i-name {
    font-size: 5rem;
    font-weight: 800;
    text-align: center;
    line-height: 1.25em;
}

.i-title {
    height: 50px;
    overflow: hidden;
}

.i-title-item {
    height: 50px;
    font-family: LotaGrotesqueAlt3-Bold;
    font-size: 42px;
    color: #DC3028; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-designer {
    font-size: 42px;
    font-weight: 700;
}

.i-description {
    text-align: center;
    padding-top: .5em;

}

.i-title-wrapper {
    height: 100%;
    animation: move 16s ease-in-out infinite alternate;
}

.i-cta-wrapper {
    padding: 16px 0px;
}

.i-resume {
    margin-right: 16px;
}

.arrow-wrapper {
    width: 100%;
    position: relative;
    display: block;
    text-align: center;
    margin-top: -40px;
    
}

.i-arrow {
    font-size: 2em;
    color: var(--color-primary-500);
}

.i-img {
    margin-right: 50px;
}

@keyframes move {
    0% {
        transform: translateY(0px); 
    } 
    
    25% {
        transform: translateY(-50px); 
    }
    50% {
        transform: translateY(-100px); 
    }
    75% {
        transform: translateY(-150px); 
    }
    100% {
        transform: translateY(-200px); 
    }
}

@media screen and (max-width: 1400px) {
    .i-2-col {
        width: 100%;
        max-width: 100%;
        margin: auto;
    }



}


@media screen and (max-width: 900px) {
    .i-2-col {
        flex-direction: column;
        height: 100%;
        padding: 0em 1em;
    }
    .i-left-wrapper {
        height: 100%;
        padding: 0px;
        justify-content: center;
    }
    .i-left {
        flex-grow: 0;
        padding: 120px 0px 64px 0px;   
    }
    .i-right {
        padding: 0px 0px 32px 0px;
    }
    .i-img {
        margin-right: 0;
    }

    .i-right {
        justify-content: start;
    }

    .i-name {
        font-size: 54px;
    }

    .i-description {
        display: none;
    }
    .arrow-wrapper {
        margin-top: 30px;
    }
}


@media screen and (max-width: 821px) {
    .i-left {
         flex-grow: 0;
         padding: 120px 0px 64px 0px;   
     }
 }
 

@media screen and (max-width: 540px) {
   .i-left {
        flex-grow: 0;
        padding: 104px 0px 0px 0px;   
    }
}

@media screen and (max-width: 480px) {
    .i-left {
        padding: 32px 0px;
    }


    .i-name {
        font-size: 54px;
    }

    .i-title {
        height: 40px;
        overflow: hidden;
    }

    .i-title-item {
        height: 40px;
        font-size: 36px;
        font-weight: 700;
        color: #DC3028; 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .i-designer {
        font-size: 36px;
        line-height: 40px;
    }

    .i-img {
        max-width: 375px;
    }
    
    @keyframes move {
        0% {
            transform: translateY(0px); 
        }     
        25% {
            transform: translateY(-40px); 
        }
        50% {
            transform: translateY(-80px); 
        }
        75% {
            transform: translateY(-120px); 
        }
        100% {
            transform: translateY(-160px); 
        }
    }
}





@media screen and (max-width: 400px) {
    .i-img {
        max-width: 200px;
        margin-top: 0px;
    }

    .i-left {
        padding: 120px 0px 0px 0px;
    }

    .i-img {
        max-width: 400px;
    }

}