.c {
    height: 100%;
    position: relative;
    min-width: 280px;
}

.c-bg {
    width: 20px;
    height: 100%;
    background-color: #dc3028;
    position: absolute;
}

.c-wrapper {
    padding: 0px 48px;
    display: flex;
    min-width: inherit;
}

.c-left {
    flex: 1;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: left;
    height: 100vh;
}

a {
    color: #1a2328;
}

.c-right {
    flex: 1;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;

}

.c-title {
    font-size: 64px;
    line-height: 64px;
    width: 100%;
}

.c-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.c-info-item {
    padding-top: 16px;
    padding-bottom: 24px;
}

.c-email {
    text-decoration: none;
    padding-left: 8px;
}

.contact-plane {
    color: var (--color-primary-500);
}


form {
    margin-top: 24px;
}

input {
    width: 100%;
    height: 48px;
    background: rgba(255,255,255,.5);
    border: none;
    border-bottom: 2px solid whitesmoke;
    margin: 10px 0px;
    font-size: 16px;
    padding-left: 12px;
}

textarea {
    width: 100%;
    margin: 10px 0px;
    background: rgba(255,255,255,.5);
    border-bottom: 2px solid whitesmoke;
    padding-left: 12px;
    padding-top: 16px;
    font-family: 'LotaGrotesqueAlt3-Regular', 'Arial', sans-serif;
    font-size: 16px;
}

textarea:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    color: fieldtext !important;
}


.confirmation-message {
    line-height: 1.2;
}

.confirmation-wrapper {
    padding: 30px 0px 30px 0px;
}

@media screen and (max-width: 900px) {
    .c-title {
        font-size: 56px;
        padding-right:10px;
    }
}


@media screen and (max-width: 756px) {
    .c-wrapper {
        flex-direction: column;
    }

    .c {
        height: 100%;
        position: relative;
    }

    .c-title {
        font-size: 42px;
        line-height: 1.2;
        margin-top: 36px;
    }

    button {
        margin-bottom: 36px;
    }
}

