.f {
    height: 140px;
    text-align: left;
    display: block;
}



.social-wrapper {
    padding: 0px 48px;
    
}

.c-social-bg {
    width: 20px;
    height: inherit;
    background-color: #dc3028;
    position: absolute;
}

.social-text {
    font-size: var(--small-text);
    font-weight: 800;
    display: block;
    margin-bottom: 0;
}


.social {
    font-size: 2em;
}

.copyright-text {
    font-size: var(--extra-small-text);
    font-weight: 600;
}
