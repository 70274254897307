.a {
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    scroll-padding-top: 70px;
    max-width: 80%;
    margin: auto;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: inherit;
}

.a-right {
    flex: 1;
}

.a-card.bg {
    position: absolute;
    top: 50px;
    left:50px;
    background-color: #2b3a42;
}

.a-card {
    aspect-ratio: 1 / 1;
    width: 70%;
    position: relative;
    overflow: hidden;
}

.a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.a-img-mercury {
    width: 30%;
    height: 30%;
    max-width: 150px;
    margin-right: 5%;
}


.a-title {
    line-height: 1.2;
}

.a-mercury-descr {
    margin: 0;
    width: auto;
}

.a-mercury {
    margin: 0px 0px 24px 0px;
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
}


.a-job-title {
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 16px;
    color: var(--color-secondary-500);
}
.a-subhead {
    font-family: 'LotaGrotesqueAlt3-Bold', Arial, Helvetica, sans-seif;
    font-size: 1.25rem;
    font-weight: 800;
    display: block;
    color: var(--color-primary-500);
}

@media screen and (max-width: 1200px) {
    /* .a-card {
        /* height: 50vh;       */
    /* }
    /* .a-card.bg {
        position: absolute;
        top: 100px;
        left:50px;
        background-color: #2b3a42;
    } */
}
@media screen and (max-width: 920px) {
    .a {
        flex-direction: column;
        height: 100%;
        width: 100%;
        max-width: 100%;
    }
    .a-left {
        max-width: 500px;
    }

    .a-card {
        position: relative;
        overflow: hidden;
        margin: 32px 32px;
        width: 100%;
    }

    .a-card.bg {
        display: none;
    }

    .a-right {
        width: 90%;
        margin-right: 0px;
    }
    .a-subhead {
        text-align: center;
    }
    .a-title{
        text-align: center;
    }
    .a-job-title {
        text-align: center;
    }

    .a-mercury-text {
        width: 100%;
    }
    .a-mercury {
        width: 100%;
        text-align: left;
    }

    .a-freetime {
        text-align: left;
    }

    .a-freetime-descr {
        text-align: left;
    }
}

 @media screen and (max-width: 480px) {

    .e-note {
        font-size: .75rem;
    }
}


@media screen and (max-width: 375px) {


    
}