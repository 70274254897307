.nav-wrapper {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
}

.navbar {
    background: var(--color-primary-500);
    height: 80px;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}

.nav-logo {
    height: 3em;
    width: auto;
}

.menu-click {
    position: relative;
    z-index: 1;
}


.home-menu-click {
    position: absolute;
    z-index: 1;

}

.menu-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    overflow: hidden;;
}

.menu-icon-burger {
    width: 50px;
    height: 6px;
    background: var(--color-white);
    border-radius: 6px;

}

.menu-icon-burger::before, .menu-icon-burger::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 6px;
    background: var(--color-white);
    border-radius: 6px;
    transition: var(--transition);
}

.menu-icon-burger::before {
    transform: translateY(-16px);
}

.menu-icon-burger::after {
    transform: translateY(16px);
}

/* Animation */

.menu-icon.open .menu-icon-burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;

}

.menu-icon.open .menu-icon-burger::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.menu-icon.open .menu-icon-burger::after {
    transform: rotate(-45deg) translate(35px, 35px);

}


.home-menu {
    background-color: var(--color-primary-700);
    position: fixed;
    top: 0px;
    right: -100%;
    height: 100vh;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-weight: 800;
    z-index: -1;
    transition: var(--transition);

}

.home-menu.active {
    right: 0px;
    box-shadow: var(--shadow-1);
}

.nav-links {
    font-size: 2em;
    font: lota-g;
}

.nav-links {
    color: var(--color-white);
}

.home-link {
    height: 44px;
}

.hidden {
    top: -80px;
    transition: var(--transition);
}